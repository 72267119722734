import { render, staticRenderFns } from "./HeaderItems.vue?vue&type=template&id=44ed8221&scoped=true&"
import script from "./HeaderItems.vue?vue&type=script&lang=js&"
export * from "./HeaderItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ed8221",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GeneralUiSvgIcon: require('/root/mohlel-front/components/general/ui/SvgIcon.vue').default,GeneralUiLanguageMenu: require('/root/mohlel-front/components/general/ui/LanguageMenu.vue').default})
