
export default {
  props: {
    headerItems: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      clipped: false,
      items: [
        // {
        //   title: `$vuetify.pages.home`,
        //   to: `/`,
        //   delay: 0.1,
        // },
        // {
        //   title: `$vuetify.pages.marketSum`,
        //   to: `/market-sum`,
        //   delay: 0.2,
        // },
        // {
        //   title: `$vuetify.pages.distributions`,
        //   to: `/distributions`,
        //   delay: 0.3,
        // },
        // {
        //   title: `$vuetify.pages.sectors`,
        //   to: `/sectors`,
        //   delay: 0.4,
        // },
        // {
        //   title: `$vuetify.home.mohlel.aboutUs`,
        //   to: `/about-us`,
        //   delay: 0.5,
        // },
        {
          title: `$vuetify.pages.subscription`,
          to: `/subscriptions`,
          delay: 0.5,
        },
        // {
        //   title: `$vuetify.pages.blogs`,
        //   to: `/blog`,
        //   delay: 0.6,
        // },
        {
          title: `$vuetify.pages.mohalelTools`,
          icon: `grid-four`,
          to: `/inspire`,
          delay: 0.7,
          children: [
            {
              icon: "eye",
              title: "$vuetify.pages.discover",
              to: "/tools/discover",
            },
            {
              icon: "shares",
              title: `$vuetify.pages.myShares`,
              to: `/dashboard/my-shares`,
              name: "shares",
            },

            {
              icon: "wallets",
              title: `$vuetify.pages.walletsManagement`,
              to: `/dashboard/wallets-management`,
              name: "wallets",
            },
            {
              icon: "favorites",
              title: `$vuetify.pages.favorites`,
              to: `/dashboard/favorites-list`,
            },

            {
              icon: "comparisons",
              title: `$vuetify.pages.comparisons`,
              to: `/dashboard/comparisons`,
              name: "comparisons",
            },
            {
              icon: "calendar",
              title: `$vuetify.pages.calendar`,
              to: `/dashboard/calendar`,
            },
            {
              icon: "news",
              title: `$vuetify.pages.news`,
              to: `/news`,
            },
            {
              icon: "newsletter",
              title: `$vuetify.labels.newsletter`,
              name: "newsLetter",
            },
          ],
        },
      ],
      drawer: false,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    scrollToNewsLetter() {
      this.$nuxt.$nextTick(() => {
        const newsletter = document.getElementById("newsletter");
        if (newsletter) {
          newsletter.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
};
