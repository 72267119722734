
import { mapState } from "vuex";
export default {
  name: "MainSearch",
  props: {
    largeSearch: {
      type: Boolean,
      required: false,
    },
    withImage: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      searchItems: [],
      searchParameter: null,
      loading: false,
    };
  },
  computed: {
    ...mapState("marketSum", ["companiesBySearch"]),
  },
  watch: {
    // whenever question changes, this function will run
    searchParameter(newValue, oldValue) {
      this.submitSearch(newValue);
      //console.log(this.searchParameter);
    },
  },

  methods: {
    async submitSearch(value) {
      if (this.searchParameter?.length >= 2) {
        this.loading = true;
        await this.$store
          .dispatch("marketSum/getCompaniesBySearch", {
            perPage: 6,
            page: 1,
            search: value,
          })
          .then(() => {
            this.searchItems = this.companiesBySearch.data.data;
            this.loading = false;
          });
      } else {
        this.searchItems = [];
      }
    },
    removeFilter() {
      this.searchParameter = null;
      this.searchItems = [];
    },
  },
};
