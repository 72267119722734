
import { mapState } from "vuex";
export default {
  name: "HeaderItems",
  data() {
    return {
      menu: [
        { title: "$vuetify.pages.profile", to: "Profile" },
        { title: "$vuetify.pages.changPassword", to: "Password" },
        { title: "$vuetify.pages.manageSub", to: "Subscriptions" },
        { title: "$vuetify.pages.transactionHistory", to: "Payments" },
      ],
      menuNotifications: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      activator: "",
    };
  },
  computed: {
    getCurrentTab() {
      let tab = null;
      if (this.$route.query) {
        if (this.$route.query.tab) {
          tab = this.$route.query.tab;
        }
      }
      return tab;
    },
    userName() {
      return this.$auth.$state.loggedIn ? this.$auth.$state.user.name : "user";
    },
    userPlan() {
      return this.$auth.$state.loggedIn ? this.$auth.$state.user.plan_name : "Plan";
    },
    userAvatar() {
      return this.$auth.$state.loggedIn ? this.$auth.$state.user.avatar : null;
    },
  },

  methods: {
    async logOut() {
      await this.$auth.logout();
      await this.$router.push("/");
    },
    ToProfilePages(item) {
      let query = {
        tab: item.to,
      };
      if (this.$route.query?.coupon) {
        query.coupon = this.$route.query.coupon;
      }
      this.$router.push(
        this.localePath({
          path: "/dashboard/account",
          query: query,
        })
      );
    },
  },
};
