
export default {
  data: () => ({}),
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
  },
  methods: {
    changeLocale(code) {
      this.$axios.setHeader("Accept-Language", code);
      this.$router.push(this.switchLocalePath(code));
      this.$vuetify.lang.current = code;
      this.$vuetify.rtl = code === "ar";
      this.$axios.defaults.headers.common["Accept-Language"] = code;
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Accept-Language": code,
        },
        redirect: "follow",
      };
      this.$store.dispatch("home/getSiteSettings", requestOptions);
      this.$auth.fetchUser();
    },
  },
};
